import React, { useState } from 'react'

import menuData from '../../assets/menuData'

import MenuList from '../menu/MenuList'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

const Menu = () => {
  const [filteredMenuHeader, setFilteredMenuHeader] = useState('Popular')
  const [filteredMenu, setFilteredMenu] = useState(
    menuData.filter((item) => item.categories.includes('Popular'))
  )

  const handleClickFilter = (e) => {
    const category = e.target.value
    const filtered = menuData.filter((item) =>
      item.categories.includes(category)
    )
    setFilteredMenu(filtered)
    setFilteredMenuHeader(category)
  }

  return (
    <section id='menu'>
      <Container>
        <Row>
          <Col>
            <h2>Menu</h2>
            <Button variant='outline-info' href='/classicmenu' rel='noreferrer'>
              Classic Menu
            </Button>
            <i>*Prices subject to change</i>
          </Col>
        </Row>
        <Row className='txtCenter'>
          <Col>
            <Button
              variant='outline-info'
              value='Popular'
              onClick={handleClickFilter}
            >
              Popular
            </Button>
            <Button
              variant='outline-info'
              value='All'
              onClick={handleClickFilter}
            >
              All
            </Button>
            <Button
              variant='outline-info'
              value='Kids Meals'
              onClick={handleClickFilter}
            >
              Kids Meals
            </Button>
            <Button
              variant='outline-info'
              value='Main Dishes'
              onClick={handleClickFilter}
            >
              Main Dishes
            </Button>
            <Button
              variant='outline-info'
              value='Main Dish Add-ons'
              onClick={handleClickFilter}
            >
              Main Dish Add-ons
            </Button>
            <Button
              variant='outline-info'
              value='Sides'
              onClick={handleClickFilter}
            >
              Sides
            </Button>
            <Button
              variant='outline-info'
              value='Drinks'
              onClick={handleClickFilter}
            >
              Drinks
            </Button>
            <Button
              variant='outline-info'
              value='Ice Cream'
              onClick={handleClickFilter}
            >
              Ice Cream
            </Button>
            <Button
              variant='outline-info'
              value='Flavors & Toppings'
              onClick={handleClickFilter}
            >
              Flavors & Toppings
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>{filteredMenuHeader}</h3>
          </Col>
        </Row>
        <MenuList menuData={filteredMenu} />
      </Container>
    </section>
  )
}

export default Menu
