import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import Loading from '../components/shared/Loading'
import Home from '../components/pages/Home'
import Menu from '../components/pages/Menu'
import Dashboard from '../components/pages/Dashboard'

import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

import 'bootstrap/dist/css/bootstrap.min.css'

const AppRouter = ({ loadUser, loading = false }) => {
  return loading ? (
    <Loading />
  ) : (
    <div>
      <Router>
        <Switch>
          <PublicRoute path='/' component={Home} exact />
          <PublicRoute path='/classicmenu' component={Menu} exact />
          <PublicRoute path='/qrmenu' component={Menu} exact />
          <PrivateRoute path='/dashboard' component={Dashboard} />
        </Switch>
      </Router>
    </div>
  )
}

export default AppRouter
