import React from 'react'

import Card from 'react-bootstrap/Card'

const MenuItem = ({ name, desc, price }) => (
  <Card>
    {/* <Card.Img variant='top' src='holder.js/100px180' /> */}
    <Card.Body>
      <Card.Title>{name}</Card.Title>
      <Card.Text>{desc}</Card.Text>
    </Card.Body>
    <Card.Footer className='text-muted'>${price.toFixed(2)}</Card.Footer>
  </Card>
)

export default MenuItem
