import React from 'react'

import Map from '../shared/Map'
import ContactForm from '../shared/ContactForm'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Contact = () => (
  <section id='contact'>
    <Container>
      <Row>
        <Col>
          <h2>Order Now!</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Map />
        </Col>
        <Col>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </section>
)

export default Contact
