import React from 'react'

import Card from 'react-bootstrap/Card'

const DrinkItem = ({ name, price }) => (
  <Card>
    {/* <Card.Img variant='top' src='holder.js/100px180' /> */}
    <Card.Body>
      <Card.Title>{name}</Card.Title>
      <Card.Text>Flavor: add it to your ice cream or drink</Card.Text>
    </Card.Body>
    <Card.Footer className='text-muted'>${price.toFixed(2)}</Card.Footer>
  </Card>
)

export default DrinkItem
