import React from 'react'

import { Link } from 'react-scroll'

import Button from 'react-bootstrap/Button'

import logo from '../../assets/image/ACLogoHero.png'
import video from '../../assets/video/hero.mp4'

const Hero = () => (
  <section id='hero'>
    <div id='backgroudVideo' className='bg-video'>
      <div className='overlay'></div>
      <video id='player' loop autoPlay playsInline muted>
        <source src={video} type='video/mp4' />
      </video>
    </div>

    <div className='center'>
      <img src={logo} alt='AC Drive-In Logo' />
      <Link to='contact' spy={true} smooth={true} offset={-70} duration={500}>
        <Button size='lg' variant='outline-info'>
          Order Now!
        </Button>
      </Link>
    </div>
  </section>
)

export default Hero
