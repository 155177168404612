import React, { Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Fragment>
            <Component {...props} />
          </Fragment>
        ) : (
          <Redirect to='/' />
        )
      }
    />
  )
}

export default PrivateRoute
