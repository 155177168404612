import React from 'react'

const Map = () => (
  <>
    <h3>Place Order In Person</h3>
    <iframe
      title='AC Drive In Map'
      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2939.8176177954506!2d-113.7887799843031!3d42.53792883184237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54ab36776b682cc5%3A0xcf257904e18f3b0!2sA%20C%20Drive-In!5e0!3m2!1sen!2sus!4v1613852918629!5m2!1sen!2sus'
      width='100%'
      height='450'
      style={{ border: 0 }}
      allowFullScreen
      loading='lazy'
    ></iframe>
  </>
)

export default Map
