import React from 'react'

import Navigation from '../shared/Navigation'
import Hero from '../sections/Hero'
import Menu from '../sections/Menu'
import History from '../sections/History'
import Contact from '../sections/Contact'

const Home = () => (
  <>
    <Navigation />
    <Hero />
    <Menu />
    <History />
    <Contact />
  </>
)

export default Home
