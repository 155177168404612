import React from 'react'
import menu from '../../assets/menu.pdf'

import AltPageNav from '../shared/AltPageNav'

const Menu = () => {
  return (
    <>
      <AltPageNav />
      <section id='classicmenu'>
        <object aria-label='Classic Menu' data={menu} />
      </section>
    </>
  )
}

export default Menu
