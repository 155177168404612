import React from 'react'
import ReactDOM from 'react-dom'

import AppRouter from './routers/AppRouter'
import './index.scss'

import firebase from 'firebase/app'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyAKGDKWatsibgPgxx8jqYKb7z6pDr6qncY',
  authDomain: 'acdrivein-935e5.firebaseapp.com',
  projectId: 'acdrivein-935e5',
  storageBucket: 'acdrivein-935e5.appspot.com',
  messagingSenderId: '935929674002',
  appId: '1:935929674002:web:fd9817efd7e1072afddbcb',
  measurementId: 'G-C5KGJ5RKKH',
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('root')
)
