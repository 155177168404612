const menu = [
  {
    name: "Kid's Meal: Hamburger",
    desc: '1 Meat, Ketchup, Pickle, Fries, and Drink',
    price: 5.5,
    categories: ['All', 'Kids Meals'],
  },
  {
    name: "Kid's Meal: Cheeseburger",
    desc: '1 Meat, 1 Cheese, Ketchup, Pickle, Fries, and Drink',
    price: 5.5,
    categories: ['All', 'Kids Meals'],
  },
  {
    name: "Kid's Meal: Chicken Nuggets",
    desc: 'Chicken Nuggets, White Sauce/Fry Sauce/BBQ Sauce, Fries, and Drink',
    price: 5.5,
    categories: ['All', 'Kids Meals'],
  },
  {
    name: "Kid's Meal: Grilled Cheese",
    desc: 'Cheese, Fries, and Drink',
    price: 5.5,
    categories: ['All', 'Kids Meals'],
  },
  {
    name: "Kid's Meal: Corn Dog",
    desc: 'Corn Dog, Mustard, Ketchup, Fries, and Drink',
    price: 5.5,
    categories: ['Popular', 'All', 'Kids Meals'],
  },
  {
    name: 'Farmer Brown',
    desc: '3 Meat, 2 Cheese, White Sauce, Lettuce, Pickle, Ketchup',
    price: 8.25,
    categories: ['Popular', 'All', 'Main Dishes'],
  },
  {
    name: 'Ranch Burger',
    desc: '2 Meat, 2 Cheese, White Sauce, Lettuce, Pickle, Tomato',
    price: 7.35,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Quarter Pounder',
    desc: '1 Meat, White Sauce, Lettuce, Ketchup, Mustard',
    price: 7,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Double Cheeseburger',
    desc: '2 Meat, 2 Cheese, White Sauce, Lettuce, Pickle',
    price: 6.75,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'A.C. Double Burger',
    desc: '2 Meat, 2 Cheese, White Sauce, Lettuce, Pickle',
    price: 5.95,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Double Burger',
    desc: '2 Meat, White Sauce, Lettuce, Pickle',
    price: 5.2,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'A.C. Cheeseburger',
    desc: '1 Meat, White Sauce, Lettuce, Pickle, Cheese, Tomato',
    price: 5.35,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'A.C. Hamburger',
    desc: '1 Meat, White Sauce, Lettuce, Pickle, Tomato',
    price: 4.6,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Cheeseburger',
    desc: '1 Meat, Cheese, White Sauce, Lettuce, Pickle',
    price: 4.6,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Hamburger',
    desc: '1 Meat, White Sauce, Lettuce, Pickle',
    price: 3.85,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Fish Burger',
    desc: 'Tarter Sauce, Lettuce',
    price: 6.7,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Chicken Burger (Grilled or Fried)',
    desc: 'White Sauce, Lettuce',
    price: 6.7,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Grilled Cheese',
    desc: 'Cheese',
    price: 3.5,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Hot Dog (Foot Long)',
    desc: 'Relish Sauce',
    price: 3.75,
    categories: ['Popular', 'All', 'Main Dishes'],
  },
  {
    name: 'Double Dog',
    desc: 'Relish Sauce, 2 Hot Dogs',
    price: 5.45,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Corn Dog',
    desc: '2 Mustard Packets',
    price: 2.35,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Taco',
    desc: 'Hot Sauce, Lettuce, Cheese',
    price: 3.3,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Burrito',
    desc: '2 Hot Sauce Packets, Deep Fried',
    price: 3,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'BLT',
    desc: 'Bacon, Lettuce, Tomato',
    price: 4.25,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Finger Steaks',
    desc: 'BBQ Sauce, White Sauce, or Fry Sauce',
    price: 8.75,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Chicken Strips',
    desc: 'BBQ Sauce, White Sauce, or Fry Sauce',
    price: 9,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Chicken Strips with Fries and Sauces',
    desc: 'BBQ Sauce, White Sauce, or Fry Sauce and Fries',
    price: 10.95,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Fish and Chips with Sauces',
    desc: 'BBQ Sauce, White Sauce, or Fry Sauce and Fries',
    price: 8.95,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Shrimp and Fries with Sauces',
    desc: 'BBQ Sauce, White Sauce, or Fry Sauce and Fries',
    price: 8.95,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Country Fried Steak on a Bun',
    desc: '1 Steak, Swiss Cheese, White Sauce, Lettuce',
    price: 8.5,
    categories: ['All', 'Main Dishes'],
  },
  {
    name: 'Extra Cheese',
    price: 0.8,
    categories: ['All', 'Main Dish Add-ons'],
  },
  {
    name: 'Extra Tomato',
    price: 0.8,
    categories: ['All', 'Main Dish Add-ons'],
  },
  {
    name: 'Jalapenos',
    price: 0.55,
    categories: ['All', 'Main Dish Add-ons'],
  },
  {
    name: 'Bacon',
    price: 1.35,
    categories: ['All', 'Main Dish Add-ons'],
  },
  {
    name: 'Meat Patty',
    price: 1.4,
    categories: ['All', 'Main Dish Add-ons'],
  },
  {
    name: 'Wiener',
    price: 1.7,
    categories: ['All', 'Main Dish Add-ons'],
  },
  {
    name: '1/4 Patty',
    price: 2.85,
    categories: ['All', 'Main Dish Add-ons'],
  },
  {
    name: 'Small Fry',
    desc: 'Fresh Cut Daily with Fry Sauce',
    price: 3.75,
    categories: ['All', 'Sides'],
  },
  {
    name: 'Large Fry',
    desc: 'Fresh Cut Daily with Fry Sauce',
    price: 4.5,
    categories: ['All', 'Sides'],
  },
  {
    name: 'Bacon Cheese Roller',
    desc: 'White Sauce/Fry Sauce, Cheese Stick Rolled in Bacon and a Slice of Cheese',
    price: 2.9,
    categories: ['Popular', 'All', 'Sides'],
  },
  {
    name: 'Cheese Sticks',
    desc: '',
    price: 5.85,
    categories: ['All', 'Sides'],
  },
  {
    name: 'Onion Rings',
    desc: '',
    price: 4,
    categories: ['All', 'Sides'],
  },
  {
    name: 'Tater Tots',
    desc: '',
    price: 3.8,
    categories: ['All', 'Sides'],
  },
  {
    name: 'Extra Sauce',
    desc: '',
    price: 0.5,
    categories: ['All', 'Sides'],
  },
  {
    name: 'Drinks',
    kinds: [
      'Pepsi',
      'Diet Pepsi',
      'Sierra Mist',
      'Mountian Dew',
      'Dr Pepper',
      'Root Beer',
      'Lemonade',
      'Orange',
      'Ice Tea',
    ],
    price: {
      sm: 2,
      md: 2.25,
      lg: 2.5,
      sm_refill: 0.85,
      md_refill: 0.9,
      lg_refill: 1,
      sm_freshLime: 2.75,
      md_freshLime: 3,
      lg_freshLime: 3.25,
      flavors: 0.25,
    },
    categories: ['All', 'Drinks'],
  },
  {
    name: 'Small Shake or Malt',
    desc: 'Chocolate, Vanilla, Twist',
    price: 5.25,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Large Shake or Malt',
    desc: 'Chocolate, Vanilla, Twist',
    price: 5.85,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Small Float or Freeze',
    desc: 'Chocolate, Vanilla, Twist',
    price: 4.5,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Large Float or Freeze',
    desc: 'Chocolate, Vanilla, Twist',
    price: 4.95,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Small Sundae',
    desc: 'Chocolate, Vanilla, Twist',
    price: 3.3,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Large Sundae',
    desc: 'Chocolate, Vanilla, Twist',
    price: 4.4,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Banana Split',
    desc: 'Traditional: Chocolate, Strawberry, Pineapple, Full Bannanna Split down the middle -OR- Your choice',
    price: 6.75,
    categories: ['Popular', 'All', 'Ice Cream'],
  },
  {
    name: 'Baby Cone',
    desc: 'Chocolate, Vanilla, Twist',
    price: 1.6,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Regular Cone',
    desc: 'Chocolate, Vanilla, Twist',
    price: 2.25,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Baby Dipped Cone',
    desc: 'Chocolate, Vanilla, Twist, with chocolate dip',
    price: 2.1,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Regular Dipped Cone',
    desc: 'Chocolate, Vanilla, Twist, with chocolate dip',
    price: 2.7,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Small Specialty Shake',
    desc: 'Peanut Butter, Fresh Banana, Hot Fudge, Oreo, Heath, Butterfinger, Cookie Dough, Mint, Chocolate Chip, *Seasonal: Pumpkin & Eggnog',
    price: 5.7,
    categories: ['All', 'Ice Cream'],
  },
  {
    name: 'Large Specialty Shake',
    desc: 'Peanut Butter, Fresh Banana, Hot Fudge, Oreo, Heath, Butterfinger, Cookie Dough, Mint, Chocolate Chip, *Seasonal: Pumpkin & Eggnog',
    price: 6.15,
    categories: ['Popular', 'All', 'Ice Cream'],
  },
  {
    name: 'Flavors & Toppings',
    kinds: [
      'Vanilla',
      'Chocolate',
      'Strawberry',
      'Caramel',
      'Butterscotch',
      'Pineapple',
      'Red Raspberry',
      'Black Raspberry',
      'Cherry',
      'Marshmallow',
    ],
    price: 0.45,
    categories: ['All', 'Flavors & Toppings'],
  },
]

export default menu
