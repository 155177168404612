import React from 'react'

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import logo from '../../assets/image/ACLogoNav.png'

const AltPageNav = () => {
  return (
    <Navbar sticky='top' className='bg-dark navbar-dark'>
      <Container>
        <a href='/#hero'>
          <Navbar.Brand>
            <img src={logo} alt='AC Drive In Logo' />
          </Navbar.Brand>
        </a>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            <a href='/#hero' className='navlink'>
              Home
            </a>
            <a href='/#menu' className='navlink'>
              Menu
            </a>
            <a href='/#history' className='navlink'>
              History
            </a>

            <a href='/#contact' className='navlink'>
              Contact
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default AltPageNav
