import React from 'react'

import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'

const DrinkItem = ({ name, price }) => (
  <Card>
    {/* <Card.Img variant='top' src='holder.js/100px180' /> */}
    <Card.Body>
      <Card.Title>{name}</Card.Title>
      <Card.Text>Ice Cold, Refreshing, Drink</Card.Text>
    </Card.Body>
    <Card.Footer className='text-muted'>
      <Table striped bordered size='sm'>
        <thead>
          <tr>
            <td></td>
            <td>Drink</td>
            <td>Refill</td>
            <td>Lime</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>s</td>
            <td>${price.sm.toFixed(2)}</td>
            <td>${price.sm_refill.toFixed(2)}</td>
            <td>${price.sm_freshLime.toFixed(2)}</td>
          </tr>
          <tr>
            <td>m</td>
            <td>${price.md.toFixed(2)}</td>
            <td>${price.md_refill.toFixed(2)}</td>
            <td>${price.md_freshLime.toFixed(2)}</td>
          </tr>
          <tr>
            <td>l</td>
            <td>${price.lg.toFixed(2)}</td>
            <td>${price.lg_refill.toFixed(2)}</td>
            <td>${price.lg_freshLime.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2}>flavors</td>
            <td colSpan={2}>${price.flavors.toFixed(2)}</td>
          </tr>
        </tbody>
      </Table>
    </Card.Footer>
  </Card>
)

export default DrinkItem
