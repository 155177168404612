import React from 'react'

import MenuItem from './MenuItem'
import DrinkItem from './DrinkItem'
import FlavorItem from './FlavorItem'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const MenuList = ({ menuData }) => {
  return (
    <Col>
      <Row>
        {menuData.map((item, idx) => {
          if (item.name === 'Drinks') {
            return item.kinds.map((name, didx) => (
              <DrinkItem key={'d' + didx} name={name} price={item.price} />
            ))
          } else if (item.name === 'Flavors & Toppings') {
            return item.kinds.map((name, fidx) => (
              <FlavorItem key={'f' + fidx} name={name} price={item.price} />
            ))
          } else {
            return (
              <MenuItem
                key={idx}
                name={item.name || ''}
                desc={item.desc || ''}
                price={item.price || ''}
              />
            )
          }
        })}
      </Row>
    </Col>
  )
}

export default MenuList
