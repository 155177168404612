import React, { useState } from 'react'

import { Link, animateScroll as scroll } from 'react-scroll'

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import logo from '../../assets/image/ACLogoNav.png'

const Navigation = () => {
  const [darknav, setDarknav] = useState(false)

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  const navbarDark = () => {
    if (window.scrollY > window.innerHeight) {
      setDarknav(true)
    } else {
      setDarknav(false)
    }
  }
  window.addEventListener('scroll', navbarDark)
  return (
    <Navbar
      sticky='top'
      className={darknav ? 'bg-dark navbar-dark' : 'navbar-dark'}
    >
      <Container>
        <Navbar.Brand onClick={scrollToTop}>
          <img src={logo} alt='AC Drive In Logo' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            <Link
              to='hero'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className='navlink'
            >
              Home
            </Link>
            <Link
              to='menu'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className='navlink'
            >
              Menu
            </Link>
            <Link
              to='history'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className='navlink'
            >
              History
            </Link>

            <Link
              to='contact'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className='navlink'
            >
              Contact
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
