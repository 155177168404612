import React from 'react'

import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'

const History = () => (
  <section id='history'>
    <Container>
      <h2>History</h2>
      <Card border='info' style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title>AC Drive In...A History</Card.Title>
          <Card.Text>
            A Fun Historical Information Section Coming Soon!
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  </section>
)

export default History
