import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import ReCAPTCHA from 'react-google-recaptcha'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

const ContactForm = () => {
  const defaultFormData = {
    email: '',
    name: '',
    subject: '',
    feedback: '',
    'g-recaptcha-response': '',
  }
  const [formAlert, setFormAlert] = useState('')
  const [formData, setFormData] = useState(defaultFormData)

  const handleFormChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const handleRecaptchaChange = (value) => {
    setFormData({ ...formData, 'g-recaptcha-response': value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    emailjs
      .send(
        'service_fk2tmzq',
        'template_1juh066',
        formData,
        'user_pHifqTqZhWWCBtjLWKxgl'
      )
      .then(
        (result) => {
          setFormAlert('Feedback has been sent, thanks!', result.text)
          setFormData(defaultFormData)
        },
        (error) => {
          setFormAlert('Sorry! Feedback error: ', error.text)
        }
      )
  }

  return (
    <>
      <h3>Call In Your Order</h3>
      <a className='phone' href='tel:+1-208-678-0141'>
        208-678-0141 (Tap To Call)
      </a>
      <h3>Give Us Some Feedback</h3>
      {formAlert && <Alert variant='info'>{formAlert}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control
            name='email'
            type='email'
            placeholder='Your Email'
            value={formData.email}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            name='name'
            type='text'
            placeholder='Your Name'
            value={formData.name}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            name='subject'
            type='text'
            placeholder='Subject of Message'
            value={formData.subject}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            name='feedback'
            placeholder='Your Feedback'
            as='textarea'
            rows={6}
            value={formData.feedback}
            onChange={handleFormChange}
          />
        </Form.Group>
        <ReCAPTCHA
          sitekey='6LdmAn4aAAAAAFWIMIkFP4vToAEh7uPEKOU9-3LE'
          onChange={handleRecaptchaChange}
        />
        <Button variant='outline-info' type='submit'>
          Submit
        </Button>
      </Form>
    </>
  )
}

export default ContactForm
